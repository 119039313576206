import {h} from "preact";

import '../i18n';
import AppState from "./app-state";

/**
 * AppI18n is a functional component that initializes the application state
 * for internationalization using the AppState component.
 *
 * @returns The application wrapped in the AppState component.
 */
const AppI18n = () => <AppState />

export default AppI18n;
