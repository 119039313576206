import {computed} from "@preact/signals";
import {h} from 'preact';
import {useContext} from "preact/hooks";

import {State, Storage} from "../../components/app-state";
import GeoCheckIn from "../../components/brevet-info/geo-check-in";
import {Brevet} from "../../models/brevet";

/**
 * Check in route properties.
 *
 * @property {string} uid - The unique identifier of the brevet.
 * @property {number} distance - The distance covered so far.
 */
type CheckInRouteProps = {
    uid: string;
    distance: number;
}

/**
 * Route component for handling check-in functionality for a brevet.
 *
 * This component retrieves brevet information based on the provided `uid`
 * and renders the {@link GeoCheckIn} component to handle the check-in process.
 *
 * @param {CheckInRouteProps} props - The component's props.
 * @param {string} props.uid - The unique identifier of the brevet.
 * @param {number} props.distance - The distance covered so far.
 * @returns {JSX.Element} The rendered {@link GeoCheckIn} component.
 */
const CheckInRoute = ({uid, distance}: CheckInRouteProps): JSX.Element => {
    const {brevets}: State = useContext(Storage);
    const brevet = computed(() => brevets.value?.find((b: Brevet) => b.uid === uid));


    return <GeoCheckIn brevet={brevet.value} distance={distance} />;
};

export default CheckInRoute;
