import {h} from "preact";
import {CookiesProvider} from "react-cookie";
import App from "./app";


/**
 * Provides cookie support for the App component.
 *
 * Wraps the App component in a <CookiesProvider> component from the
 * react-cookie library. This enables the App component to read and write
 * cookies.
 *
 * @returns The App component wrapped in a <CookiesProvider> component.
 */
const AppCookie = () => (
        <CookiesProvider>
            <App />
        </CookiesProvider>
    );

export default AppCookie;
