import Button from "muicss/lib/react/button";
import {Fragment, h} from 'preact';
import {useCookies} from "react-cookie";
import {Trans, useTranslation} from "react-i18next";

import {Provider, providerOptions} from "../../models/provider";
import {oauthRedirect} from "../../services/auth";

import style from './style.scss';

/**
 * The EmailUsedDialog properties
 * @property {string} email - The user's email address.
 * @property {string} provider - The name of the OAuth provider (e.g., "google", "facebook").
 */
type EmailUsedDialogProps = {
    email: string;
    provider: Provider;
}

/**
 * Displays a dialog informing the user that their email address is already associated with a specific OAuth provider.
 * Prompts the user to sign in using that provider.
 *
 * @param {EmailUsedDialogProps} props - Component properties.
 * @param {string} props.email - The user's email address.
 * @param {string} props.provider - The name of the OAuth provider (e.g., "google", "facebook").
 * @returns {JSX.Element} The rendered dialog.
 */
const EmailUsedDialog = ({email, provider}: EmailUsedDialogProps): JSX.Element => {
    const {t} = useTranslation();
    const setCookie = useCookies(["state", "provider"])[1];
    const providerName: string = providerOptions[provider].name;

    /**
     * Initiates the OAuth sign-in flow with the specified provider.
     *
     * Generates a random state value for CSRF protection, stores it and the provider name in cookies,
     * and then redirects the user to the provider's OAuth authorization endpoint.
     */
    const signInWithProvider = () => {
        const state = (Math.random() * Date.now()).toString();
        setCookie('state', state, {path: '/'});
        setCookie('provider', provider, {path: '/'});
        oauthRedirect(provider, state)
    }

    return (
        <>
            <h2>{t("emailUsed.title")}</h2>
            <h3>{t("emailUsed.description")}</h3>
            <Trans i18nKey="emailUsed.suggestion">
                You've already used <strong>{{email}}</strong>. Sign in with {{provider: providerName}} to continue.
            </Trans>
            <div className={style.actions}>
                <Button variant="raised" color="primary"
                        onClick={signInWithProvider}>{t('login.signInWith', {provider: providerName})}</Button>
            </div>
        </>
    )
}

export default EmailUsedDialog;
