/**
 * Service to store and get values from the local storage.
 *
 * The stored values are prefixed with SETTINGS_PREFIX.
 *
 */
export const SETTINGS_PREFIX = 'brevet.top/';

/**
 * Gets a value from the local storage.
 *
 * @param key The key name
 * @returns The value converted from a JSON notation or undefined.
 */
export function getValue(key: string) {
  const value = localStorage.getItem(SETTINGS_PREFIX + key);
  return value === 'undefined' ? undefined : JSON.parse(value || 'null');
}

/**
 * Sets a value in the local storage.
 *
 * @param key The key name to store the value under.
 * @param value The value to be stored, which will be serialized to JSON.
 */
export function setValue(key: string, value: unknown): void {
  localStorage.setItem(SETTINGS_PREFIX + key, JSON.stringify(value));
}

/**
 * Removes the key from the local storage.
 *
 * @param key The key name to remove.
 */
export function removeKey(key: string) {
  localStorage.removeItem(SETTINGS_PREFIX + key);
}

/**
 * Injects a token-value pair into the object in the storage.
 *
 * @param key The key name of the object to store the token-value pair under.
 * @param token The token name to store the value under.
 * @param value The value to be stored.
 * @returns true if success, false if the type of the value associated with the key is not object.
 */
export function injectToken(key: string, token: string, value: unknown): boolean {
  const storage = getValue(key);

  if (!storage) {
    setValue(key, {[token]: value});
    return true;
  } else if (storage instanceof Object) {
    storage[token] = value;
    setValue(key, storage);
    return true;
  }
  console.error(`Unsupported type of ${key}`);
  return false;
}

/**
 * Replaces (renames) an existing token with a new one in the object stored under the specified key.
 *
 * @param key The key name of the object in the storage.
 * @param oldToken The token name to be replaced.
 * @param newToken The new token name to be used.
 * @returns The new token name.
 *
 * Logs an error if the key is not found, if the old token does not exist,
 * or if the value associated with the key is not an object.
 */
export function replaceToken(key: string, oldToken: string, newToken: string): string {
  const storage = getValue(key);

  if (!storage) {
    console.error(`Key not found: ${key}`);
  } else if (storage instanceof Object) {
    if (oldToken in storage) {
      storage[newToken] = storage[oldToken];
      delete storage[oldToken];
      setValue(key, storage);
    } else {
      console.error(`Token not found in ${key}: ${oldToken}`);
    }
  } else {
    console.error(`Unsupported type of ${key}`);
  }
  return newToken;
}

/**
 * Removes the specified token from the object stored under the specified key.
 *
 * @param key The key name of the object in the storage.
 * @param token The token name to be removed.
 *
 * Logs an error if the key is not found, if the token does not exist,
 * or if the value associated with the key is not an object.
 */
export function removeToken(key: string, token: string) {
  const storage = getValue(key);

  if (!storage) {
    console.error(`Key not found: ${key}`);
  } else if (storage instanceof Object) {
    if (token in storage) {
      delete storage[token];
      setValue(key, storage);
    } else {
      console.error(`Token not found in ${key}: ${token}`);
    }
  } else {
    console.error(`Unsupported type of ${key}`);
  }
}
