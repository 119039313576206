import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {sendRecover} from "../../services/auth";

import style from './style.scss';

/**
 * The PasswordRecoverDialog properties
 * @property {Signal<EmailFlow>} state - The EmailFlow state signal.
 */
type PasswordRecoverDialogProps = {
    state: Signal<EmailFlow>;
}

/**
 * Represents the state of the PasswordRecoverDialog.
 *
 * @property {string} email - The email address associated with the account to recover.
 * @property {string} error - An error message, if one occurred during the recovery process. Empty string if no error.
 */
type PasswordRecoverDialogState = {
    email: string;
    error: string;
}

/**
 * A dialog component for password recovery.
 *
 * This component allows users to enter their email address to initiate
 * the password recovery process. It validates the email address and
 * sends a recovery request to the server. It displays an error message
 * if the email is invalid or not found, and updates the state upon
 * successful email submission.
 *
 * @param {PasswordRecoverDialogProps} props - The component's props.
 * @param {Signal<EmailFlow>} props.state - The current state of the email login flow, containing the user's email.
 * @returns {JSX.Element} The rendered password recovery dialog.
 */
const PasswordRecoverDialog = ({state}: PasswordRecoverDialogProps): JSX.Element => {
    const {t} = useTranslation();

    const [credentials, setState] = useState<PasswordRecoverDialogState>({email: state.value.email, error: null});
    /**
     * Updates the email address in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the email input field.
     * @returns {void}
     */
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    /**
     * Updates the error state within the component.
     *
     * @param {string} state - The error message to display. This should be a human-readable
     * string describing the error that occurred.
     * @returns {void}
     */
    const updateError = (state: string) => setState({...credentials, error: state});

    /**
     * Submits the user's email address for password recovery.
     *
     * This function validates the provided email address and, if valid,
     * sends a password recovery request to the server.  If the request
     * is successful, the state is updated to reflect that the recovery
     * email has been sent. If the request fails, an error message is displayed.
     */
    const submitEmail = () => {
        if (!credentials.email) {
            return;
        }
        // reset the error state
        updateError(null);
        sendRecover(credentials.email)
            .then(info => {
                state.value = new EmailFlow(EMailLogin.EmailSent, {email: info.email})
            })
            .catch(error => {
                console.error("error", error);
                // EMAIL_NOT_FOUND or INVALID_EMAIL
                updateError(error.message)
            })
    }
    return (
        <>
            <h2>{t("passwordRecover.title")}</h2>
            <p>{t("passwordRecover.suggestion")}</p>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("passwordRecover.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("passwordRecover.noMatch")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("passwordRecover.cancel")}</Button>
                <Button onClick={submitEmail} variant="raised" color="primary">{t("passwordRecover.send")}</Button>
            </div>
        </>
    )
}

export default PasswordRecoverDialog;
