// FIXME: LatLng + nested fields
import {LngLat} from "../../models/track-point";

/**
 * Converts a Firestore document format into a JavaScript object.
 *
 * This function takes an object representing a Firestore document (with fields that may include
 * `mapValue`, `arrayValue`, `timestampValue`, `integerValue`, `doubleValue`, `geoPointValue`, `stringValue`,
 * `booleanValue`, and `nullValue`) and converts it back into a standard JavaScript object.
 * The conversion is done according to the type of each field:
 * - `mapValue` is recursively converted into a JavaScript object.
 * - `arrayValue` is converted into a JavaScript array of values.
 * - `timestampValue` is converted into a JavaScript Date object.
 * - `integerValue` is parsed into a JavaScript number.
 * - `doubleValue` is parsed into a JavaScript floating-point number.
 * - `geoPointValue` is converted into an instance of LngLat.
 * - `stringValue`, `booleanValue`, and `nullValue` are returned as their respective JavaScript types.
 *
 * @param {object} fields - An object representing the Firestore document fields to be converted.
 * @returns {object} - A JavaScript object representing the converted document.
 *
 * @example
 * const firestoreDoc = {
 *   name: { stringValue: 'Alice' },
 *   age: { integerValue: 30 },
 *   active: { booleanValue: true },
 *   createdAt: { timestampValue: '2023-10-01T12:00:00Z' }
 * };
 * const jsonObject = documentToJson(firestoreDoc);
 * // Returns:
 * // {
 * //   name: 'Alice',
 * //   age: 30,
 * //   active: true,
 * //   createdAt: new Date('2023-10-01T12:00:00Z')
 * // }
 */
export function documentToJson(fields) {
    const result = {};
    for (const key in fields) {
        const value = fields[key]
        if ('mapValue' == key) {
            return documentToJson(value.fields || {});
        }
        if ('arrayValue' == key) {
            return value.values ? value.values.map(l => documentToJson(l)) : [];
        }
        if ('timestampValue' == key) {
            return new Date(value);
        }
        if ('integerValue' == key) {
            return parseInt(value, 10);
        }
        if ('doubleValue' == key) {
            return parseFloat(value);
        }
        if ('geoPointValue' == key) {
            return new LngLat(value.longitude, value.latitude);
        }
        const item = ['stringValue', 'booleanValue', 'nullValue'].find(t => t === key)
        if (item) {
            return value;
        }

        result[key] = documentToJson(value)
    }
    return result;
}
