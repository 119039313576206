import {effect, Signal, signal} from "@preact/signals";
import {createContext, h} from "preact";
import {useEffect} from "preact/hooks";

import {Brevet} from "../models/brevet";
import {loadProgress, ProgressStorage} from "../models/progress";
import {RiderPrivateDetails, RiderPublicDetails} from "../models/rider";
import {getValue, setValue} from "../services/settings";
import loadBrevets from "../utils/load-brevets";
import AppCookie from "./app-cookie";
import {TAuth, TRefreshTokens} from "../models/auth";

import {refreshTokens} from "../services/auth";

const REFRESH_10_MIN = 10 * 60 * 1000;

export type State = {
    brevets: Signal<Brevet[]>;
    expand: Signal<string[]>;
    loading: Signal<boolean>;
    error: Signal<string|null>;
    auth: Signal<TAuth>;
    riderPublic: Signal<RiderPublicDetails>;
    riderPrivate: Signal<RiderPrivateDetails>;
    progress: Signal<ProgressStorage>;
}


export const Storage = createContext<State>(null);

/**
 * Creates an initial state for the application.
 *
 * The state is a mapping of Signals which contains the following properties:
 *
 * - `brevets`: A list of all brevets.
 * - `expand`: A list of brevet IDs which are expanded.
 * - `loading`: A boolean indicating whether the application is currently loading.
 * - `error`: An error message, or null if there is no error.
 * - `auth`: The authentication state.
 * - `riderPublic`: The public rider info.
 * - `riderPrivate`: The private rider info.
 * - `progress`: The progress of the rider in the brevets.
 *
 * The state is loaded from the browser's local storage, or initialized to default values if no data is found.
 *
 * @returns {State} The initial state of the application.
 */
const createState = (): State => {
    const brevets: Signal<Brevet[]> = signal(loadBrevets(getValue('brevets') || []));
    const expand: Signal<string[]> = signal(getValue('expand') || []);
    const loading: Signal<boolean> = signal(false);
    const error: Signal<string> = signal(null);
    const auth: Signal<TAuth> = signal(getValue('auth'));
    const riderPublic: Signal<RiderPublicDetails> = signal(getValue('riderPublic'));
    const riderPrivate: Signal<RiderPrivateDetails> = signal(getValue('riderPrivate'));
    const progress: Signal<ProgressStorage> = signal(loadProgress(getValue('progress')) || {});
    // TODO: wipe out old progress

    return {brevets, expand, loading, error, auth, riderPublic, riderPrivate, progress};
}

const initialState = createState();
effect(() => {
    setValue('brevets', initialState.brevets.value)
});
effect(() => {
    setValue('expand', initialState.expand.value)
});
effect(() => {
    setValue('auth', initialState.auth.value)
});
effect(() => {
    setValue('riderPublic', initialState.riderPublic.value)
});
effect(() => {
    setValue('riderPrivate', initialState.riderPrivate.value)
});
effect(() => {
    setValue('progress', initialState.progress.value)
});

/**
 * This component wraps the application in a context provider with a state.
 *
 * It also starts a timer that refreshes the authentication token every 10
 * minutes.
 *
 * The application state is stored in the browser's local storage, and is
 * initialized from there if available, or to default values if not.
 *
 * The state is also updated whenever the user navigates to a new page.
 *
 * @returns The application wrapped in a context provider.
 */
const AppState = () => {
    useEffect(() => {
        const interval = setInterval(() => {
            // skip polling if no internet or no tokens
            if (navigator.onLine && initialState.auth.value && 'refreshToken' in initialState.auth.value) {
                refreshTokens(initialState.auth.value.refreshToken)
                    .then((json: TRefreshTokens) => initialState.auth.value = {
                        ...initialState.auth.value,
                        idToken: json.id_token,
                        refreshToken: json.refresh_token,
                        oauthAccessToken: json.access_token,
                    })
                    .catch(error => console.error("Token refresh error", error))
            }
        }, REFRESH_10_MIN);
        return () => clearInterval(interval);
    }, []);

    return (
        <Storage.Provider value={initialState}>
            <AppCookie />
        </Storage.Provider>
    );
}

export default AppState;
