/**
 * Custom error class representing an offline state.
 *
 * @augments {Error}
 */
export class Offline extends Error {
    /**
     * Creates a new Offline error.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, Offline.prototype);
    }
}
