import {Barcode, EBarcodeSource, TBarcode, TBarcodeListItem, TBarcodeQueue, TSavedBarcode} from "../../models/barcode";
import {NONE_CHECKPOINT} from '../../models/checkpoint';
import {Offline} from "../../models/offline";
import {createBarcode} from "../firestore-api";
import {getValue, injectToken, removeToken, replaceToken} from "../settings";
import {TAuth} from "../../models/auth";

// 12 days for timestamp comparison - used to remove old barcodes from the queue
const DOZEN_DAYS_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 12;
const PREFIX = 'random_';


/**
 * Enqueues a barcode, handling both online and offline scenarios.
 *
 * This function generates a unique identifier for the barcode, stores it in a queue,
 * and attempts to send it to the server if the application is online. If the
 * application is offline, it immediately rejects with an Offline error.
 *
 * @param source - The source of the barcode, either a rider or a checkpoint.
 * @param sourceUid - The UID of the rider or checkpoint. Defaults to NONE_CHECKPOINT.
 * @param barcode - The barcode to enqueue.
 * @param auth - Authentication object containing tokens.
 *
 * @returns A promise that resolves to the UID of the created barcode on successful
 * server-side processing, or rejects with an error if the process fails.
 *
 * @throws Offline - If the application is offline.
 */
export function enqueueBarcode(
    source: EBarcodeSource,
    // rider uid or checkpoint uid
    sourceUid: string = NONE_CHECKPOINT,
    barcode: TBarcode,
    auth: TAuth,
): Promise<string> {
    const barcodeUid = PREFIX + Math.random().toString(36).substring(2, 11);

    injectToken('barcodes', barcodeUid, {
        source, sourceUid, barcode, token: auth.refreshToken
    });

    if (navigator.onLine) {
        // send old codes from the queue
        repeatSending(barcodeUid);

        // the latest code
        return createBarcode(source, sourceUid, barcode, auth.refreshToken)
            .then((uid: string) => replaceToken('barcodes', barcodeUid, uid))
            .catch(error => {
                console.error('Barcode creation failure', error);
                return Promise.reject(error);
            });
    }
    return Promise.reject(new Offline('working offline'));
}


/**
 * Repeats sending old barcodes from the queue.
 *
 * This function is used in online mode to send old barcodes from the queue that
 * were stored when the application was offline. It cleans up old records that
 * are no longer of interest and sends the remaining ones to the server.
 *
 * @param {string} [skip] - The UID of the barcode to skip (the most recent one
 *   is being sent immediately).
 * @param {boolean} [force=false] - If true, forces sending the code again even
 *   if it was successfully delivered.
 */
export function repeatSending(skip?: string, force = false) {
    const savedCodes: TBarcodeQueue = getValue('barcodes') || {};
    const cutOffDate = new Date(Date.now() - DOZEN_DAYS_IN_MILLISECONDS);

    // check all the records
    for (const oldUid in savedCodes) {
        const savedCode: TSavedBarcode = savedCodes[oldUid];

        // confirmed transmission doesn't have a temporary UID prefix
        if (!oldUid.startsWith(PREFIX)) {
            // cleanup of old records out of interest
            if (savedCode.barcode.time < cutOffDate) {
                removeToken('barcodes', oldUid);
            }
            // may force sending the code again even if successfully delivered
            if (!force) {
                continue;
            }
        }
        // the most recent code is being sent immediately
        if (oldUid === skip) {
            continue;
        }

        // send the temporary code again
        createBarcode(savedCode.source, savedCode.sourceUid, savedCode.barcode, savedCode.token)
            .then(uid => replaceToken('barcodes', oldUid, uid));
    }
}

/**
 * Retrieves and lists the saved barcodes from the queue.
 *
 * This function fetches the saved barcodes from the queue, processes them into an array of
 * objects containing relevant details such as the time of the barcode, the barcode itself,
 * and whether it has been sent (indicated by the UID prefix). The resulting array is then sorted
 * in descending order based on the time of the barcodes.
 *
 * @returns {Array<TBarcodeListItem>} - An array of {@link TBarcodeListItem} objects representing
 *                                                           the saved barcodes, each containing
 *                                                           the time, code, and sent status.
 *
 * @example
 * const queueList = listQueue();
 * console.log('Barcode Queue:', queueList);
 */
export function listQueue() {
    const savedCodes = getQueue()

    return Object.entries(savedCodes)
        .map(([uid, value]) => ({
            time: value.barcode.time as Date,
            code: value.barcode.code,
            sent: !uid.startsWith(PREFIX),
        } as TBarcodeListItem))
        .sort((a, b) => b.time.getTime() - a.time.getTime());
}

/**
 * Retrieves the current barcode queue from storage.
 *
 * This function fetches the saved barcodes from persistent storage using a specified key.
 * It processes each entry in the retrieved queue, converting the stored barcode data JSON into
 * instances of the {@link Barcode} class. The resulting object represents the current state of the
 * barcode queue and is returned for further use.
 *
 * @returns {TBarcodeQueue} - A {@link TBarcodeQueue} object representing the current barcode queue, where each entry
 *                            contains the barcode data as instances of the {@link Barcode} class.
 *
 * @example
 * const barcodeQueue = getQueue();
 * console.log('Current Barcode Queue:', barcodeQueue);
 */
export function getQueue(): TBarcodeQueue {
    const savedCodes: TBarcodeQueue = getValue('barcodes') || {};

    Object.entries(savedCodes).forEach(([uid, value]) => savedCodes[uid].barcode = Barcode.fromDoc(value.barcode));
    return savedCodes;
}
