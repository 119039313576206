import {h} from "preact"
import {useContext} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {State, Storage} from "../app-state";
import LinkButton from "../link-button";

import style from './style.scss';

/**
 * Renders a button that navigates to either the login page or the settings page,
 * depending on the authentication status.
 *
 * If the user is logged in (auth.value is not null), the button navigates to the settings page
 * and displays a "person" icon.  If the user is not logged in, the button navigates to
 * the login page and displays a "login" icon.
 *
 * @returns {JSX.Element} A button element.
 */
const LoginSettingsButton = (): JSX.Element => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage);

    return (
        <div className={style.logInContainer}>
            {auth.value !== null ? (
                <LinkButton size="small" variant="fab" className={style.logInButton}
                            to="/settings" title={t('login.settings')}>
                    <i className="material-icons">person</i>
                </LinkButton>
            ) : (
                <LinkButton size="small" variant="fab" className={style.logInButton}
                            to="/login" title={t('login.login')}>
                    <i className="material-icons">login</i>
                </LinkButton>
            )}
        </div>
    )
}

export default LoginSettingsButton;
