import {Provider} from "./provider";

/**
 * Represents the different stages of the email login/signup flow.
 */
export enum EMailLogin {
    /** User is prompted to enter their email address. */
    EnterEmail = "email",
    /** The entered email is already associated with an OAuth account. */
    EmailUsed = "used",
    /** The entered email is valid and the user should proceed to enter their password. */
    EmailOk = "password",
    /** The entered password is incorrect. */
    WrongPassword = "wrong",
    /** The account doesn't exist and will be created. */
    CreateAccount = "create",
    /** The user initiates the password recovery process. */
    RecoverPassword = "recover",
    /** A password recovery email has been sent to the user. */
    EmailSent = "sent"
}

/**
 * Optional properties for the email flow state.
 *
 * @property {string} [email] - The user's email address.
 * @property {Provider} [provider] - The authentication provider (if applicable).
 */
type TEmailFlowOptional = {
    email?: string;
    provider?: Provider;
}

/**
 * Represents the state of the email login/signup flow.
 *
 * @property {EMailLogin} status - The current stage of the flow.
 * @property {string} [email] - The user's email address.
 * @property {Provider} [provider] - The authentication provider (if applicable).
 */
type TEmailFlow = TEmailFlowOptional & {
    status: EMailLogin;
}

/**
 * EmailFlow class manages the state of an email login process, implementing the {@link TEmailFlow} interface.
 * It encapsulates the current status of the login flow, the user's email, and the email provider.
 *
 * This class provides:
 * - Initialization of the email login flow with a default or specified status.
 * - Optional configuration of the email and provider through the constructor.
 *
 * @implements {TEmailFlow}
 */
export class EmailFlow implements TEmailFlow {
    status = EMailLogin.EnterEmail;
    email = "";
    provider: Provider = null;

    /**
     * Constructor of the class
     * @param {EMailLogin} [status=EMailLogin.EnterEmail] - The initial status of the email login flow.
     * @param {TEmailFlowOptional} [options] - An optional object containing {@link TEmailFlowOptional} properties
     * to overwrite the default email and provider.
     */
    constructor(status: EMailLogin = EMailLogin.EnterEmail, options: TEmailFlowOptional = {}) {
        this.status = status;
        // overwrite email and provider if given
        Object.assign(this, options);
    }
}