import environment from "../environment";

const FACEBOOK_URL = "https://www.facebook.com/v15.0/dialog/oauth"
const GOOGLE_URL = "https://accounts.google.com/o/oauth2/v2/auth"
const BALTIC_STAR_URL = "https://auth.balticstar.spb.ru/authorize"


/**
 * Enum of supported providers.
 * @enum {string}
 */
export enum Provider {
    BalticStar = "oidc.baltic_star_token",
    Facebook = "facebook.com",
    Google = "google.com",
}

export interface ProviderOptions {
    [index: string]: string;
}

type EnumDictionary<T extends string, U> = {
    [K in T]: U;
};

/**
 * Mapping of the provider custom options like scope, base URL, token type, etc.
 */
export const providerOptions: EnumDictionary<Provider, ProviderOptions> = {
    [Provider.BalticStar]: {
        name: "Baltic star",
        client_id: environment.BALTIC_STAR_CLIENT_ID,
        scope: "openid profile email",
        baseUrl: BALTIC_STAR_URL,
        response_type: "token",
    },
    [Provider.Facebook]: {
        name: "Facebook",
        client_id: environment.FACEBOOK_CLIENT_ID,
        scope: "public_profile email",
        baseUrl: FACEBOOK_URL,
        response_type: "token",
    },
    [Provider.Google]: {
        name: "Google",
        client_id: environment.GOOGLE_CLIENT_ID,
        scope: "profile email",
        baseUrl: GOOGLE_URL,
        response_type: "id_token",
    },
}