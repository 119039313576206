import {Signal} from "@preact/signals";
import {Feature} from 'geojson';
import * as L from "leaflet";
import "leaflet.locatecontrol/src/L.Control.Locate";
import {Component, h} from 'preact';

import i18n from "../../i18n";
import {Checkpoint} from "../../models/checkpoint";
import {LngLat} from "../../models/track-point";
import {validLngLat} from "../../utils/lnglat";

import "leaflet.locatecontrol/src/L.Control.Locate.scss";
import 'leaflet/dist/leaflet.css';

const RADIUS = 1200;

/**
 * Properties for the LeafletMap component.
 * @property {Checkpoint[]} [checkpoints] - An array of checkpoints to display on the map.
 * @property {Feature} [geoJson] - GeoJSON data to display on the map.
 *  Can be a single GeoJSON object or an array of GeoJSON objects.
 * @property {LngLat} [startPoint] - The initial map center coordinates.
 * @property {Signal<LngLat>} [geoLocation] - A signal providing the user's current location.
 */
type LeafletMapProps = {
    checkpoints?: Checkpoint[];
    geoJson?: Feature;
    startPoint?: LngLat;
    geoLocation?: Signal<LngLat>;
}

const DEFAULT_CENTER = new LngLat(30.317, 59.95);
const DEFAULT_ZOOM = 14;

/**
 * A Preact component that wraps a Leaflet map.
 *
 * By default, the map is centered on the first checkpoint.
 *
 * @property {Checkpoint[]} [checkpoints] - An array of Checkpoint objects.
 * @property {any} [geoJson] - A GeoJSON object to be displayed on the map.
 * @property {LngLat} [startPoint] - An optional LngLat to center the map on.
 * @property {Signal<LngLat>} [geoLocation] - An optional Signal of the user's location.
 */
class LeafletMap extends Component<LeafletMapProps> {
    map: L.Map;
    geoLocation?: Signal<LngLat>;
    locateControl: L.Control;


    /**
     * Constructs a new LeafletMap component.
     *
     * @param {LeafletMapProps} props - The component props.
     */
    constructor(props: LeafletMapProps) {
        super(props);
        this.geoLocation = props.geoLocation;
    }

    /**
     * Create a placeholder for the map. To be updated by the framework.
     * @returns {JSX.Element}
     */
    render = (): JSX.Element => <div id="map" style={{height: '20rem', maxWidth: '800px'}} />

    /**
     * Initializes the Leaflet map upon component mounting.
     *
     * - Sets the initial view of the map to the starting point, first checkpoint, or default center.
     * - Adds a tile layer from OpenStreetMap with a maximum zoom level of 19.
     * - If a GeoJSON object is provided, it is added to the map with a specific red style.
     * - Iterates over valid checkpoint coordinates to:
     *   - Add a popup displaying the checkpoint name or a default label.
     *   - Add a green circle around the checkpoint if geoLocation is available.
     * - If geoLocation is available, a location control is added to the map to:
     *   - Enable fly-to-location and display of a compass.
     *   - Start location tracking and handle location found and error events.
     */
    componentDidMount() {
        this.map = L.map('map')
            .setView(this.props.startPoint || this.props.checkpoints?.[0]?.coordinates || DEFAULT_CENTER, DEFAULT_ZOOM);

        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(this.map);

        if (this.props.geoJson) {
            L.geoJSON(this.props.geoJson, {
                style: {
                    color: 'red',
                    weight: 2,
                    opacity: 0.5,
                    lineJoin: 'round',
                    lineCap: 'round',
                },
            }).addTo(this.map);
        }

        this.props.checkpoints?.filter(cp => validLngLat(cp.coordinates)).reverse()
            .forEach(cp => {
                    L.popup({
                        keepInView: false,
                        closeButton: false,
                        closeOnClick: false,
                        closeOnEscapeKey: false,
                    })
                        .setLatLng(cp.coordinates)
                        .setContent(cp.name || i18n.t("map.control"))
                        .addTo(this.map);
                    if (this.geoLocation) {
                        L.circle(cp.coordinates, {radius: RADIUS, weight: 1, color: 'green'}).addTo(this.map);
                    }
                }
            );

        if (this.geoLocation) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.locateControl = L.control.locate({
                flyTo: true,
                showCompass: true,
                cacheLocation: true
            }).addTo(this.map);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.locateControl.start();
            this.map.on('locationfound', this.onLocationFound.bind(this));
            this.map.on('locationerror', event => console.error('Geo location error', event));
        }
    }


    /**
     * Cleans up resources and stops location tracking when the component is about to unmount.
     */
    componentWillUnmount() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.locateControl?.stop();
    }


    /**
     * Event handler for when a location is found by the browser's geolocation API.
     *
     * @param event - The geolocation event.
     */
    onLocationFound(event: GeolocationCoordinates) {
        this.geoLocation.value = new LngLat(event.longitude, event.latitude);
    }
}

export default LeafletMap;
