import {LngLat} from "../models/track-point";

/**
 * Validates whether the provided coordinates object contains valid latitude and longitude values.
 *
 * @param {LngLat} coordinates - An object representing geographical coordinates,
 *                                expected to have `lat` and `lng` properties.
 *                                The `lat` property represents latitude, and the `lng` property represents longitude.
 *
 * @returns {boolean} Returns true if the coordinates object is defined and both `lat` and `lng` properties are truthy;
 *                   otherwise, returns false.
 *
 * @example
 * const coords = { lat: 40.7128, lng: -74.0060 };
 * console.log(validLngLat(coords)); // true
 *
 * const invalidCoords = { lat: null, lng: -74.0060 };
 * console.log(validLngLat(invalidCoords)); // false
 */
export function validLngLat(coordinates: LngLat): boolean {
    return !!(coordinates && coordinates.lat && coordinates.lng);
}
