import {Brevet, TBrevetShort} from './brevet';
import {RiderCheckIn} from './rider-check-in';
import {LngLat} from "./track-point";


export const NONE_CHECKPOINT = 'none';

export type TCheckpoint = {
  name: string;
  uid: string;
  // from the start of the route
  distance?: number;
  sleep?: boolean;
  selfCheck?: boolean;

  coordinates?: LngLat;
  startDate?: Date;
  endDate?: Date;

  // Checkpoint info page, brevet info
  brevet?: Brevet;

}

/**
 * Represents a checkpoint in a route.
 *
 * @class Checkpoint
 */
export class Checkpoint {
  name: string;
  uid = NONE_CHECKPOINT;
  // from the start of the route
  distance?: number;
  sleep?: boolean;
  selfCheck?: boolean;

  coordinates?: LngLat;
  startDate?: Date;
  endDate?: Date;

  // to the current position
  delta?: number;
  copy?: boolean;

  // Checkpoint info page, brevet info
  brevet?: TBrevetShort;

  // Checkpoint info page, rider table
  riders?: RiderCheckIn[];

  /**
   * Creates a new Checkpoint instance.
   * @param {string} name - The name of the checkpoint.
   * @param {number} distance - The distance from the start of the route.
   * @param {LngLat} coordinates - The geographical coordinates of the checkpoint.
   */
  constructor(name: string, distance: number, coordinates: LngLat) {
    this.name = name;
    this.distance = distance;
    this.coordinates = coordinates;
  }

  /**
   * Create an object of class Checkpoint using properties given.
   *
   * @param doc - a document with checkpoint description
   * @returns A new Checkpoint object.
   */
  static fromDoc(doc: TCheckpoint): Checkpoint {
    const {name, distance, coordinates, ...rest} = doc;
    const checkpoint = new Checkpoint(name || rest["displayName"], distance, coordinates);
    Object.assign(checkpoint, rest);
    return checkpoint;
  }

  /**
   * Tests if the checkpoint is active based on a timestamp given.
   * A brevet the checkpoint is assigned to must have startDate...endDate range covering the timestamp.
   *
   * @param time - current {@link Date}
   * @returns True if the checkpoint is active, false otherwise.
   */
  isOnline(time: Date): boolean {
    return (!this.startDate || this.startDate <= time)
      && (!this.endDate || this.endDate >= time);
  }
}
