import {h} from 'preact';
import {Route, Router} from 'preact-router';

// Code-splitting is automated for `routes` directory
import BrevetInfoRoute from "../routes/brevet-info";
import CheckInRoute from "../routes/brevet-info/check-in";
import BrevetListRoute from "../routes/brevet-list";
import CheckpointInfoRoute from "../routes/checkpoint-info";
import LoginRoute from "../routes/login";
import EmailLoginRoute from "../routes/login/email";
import SettingsRoute from "../routes/settings";
import {repeatSending} from "../services/barcode-queue";

import Header from './header';

/**
 * The main App component, which wraps the whole application.
 *
 * This component simply renders an outer `<div id="app">` with a {@link Header}
 * inside it, and a `<main>` element containing the React Router's {@link Router}
 * component.
 *
 * The routes are as follows:
 *
 * - `/`: The root route redirects to `/brevets`.
 * - `/brevets`: The list of brevets.
 * - `/brevet/:uid`: The details of a brevet with the given `uid`.
 * - `/brevet/:uid/checkin/:distance`: The check-in page for a brevet with the given `uid`.
 * - `/brevet/:brevetUid/checkpoint/:checkpointUid`: The details of a checkpoint
 *   with the `checkpointUid` in a brevet with the `brevetUid`.
 * - `/login`: The login page.
 * - `/login/email`: The login page with email workflow.
 * - `/settings`: The settings page.
 *
 * @returns The App component.
 */
const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Route path="/" component={BrevetListRoute} />
				<Route path="/brevets" component={BrevetListRoute} />
				<Route path="/brevet/:uid" component={BrevetInfoRoute} />
				<Route path="/brevet/:uid/checkin/:distance" component={CheckInRoute} />
				<Route path="/brevet/:brevetUid/checkpoint/:checkpointUid" component={CheckpointInfoRoute} />
				<Route path="/login" component={LoginRoute} />
                <Route path="/login/email" component={EmailLoginRoute} />
				<Route path="/settings" component={SettingsRoute} />
			</Router>
		</main>
	</div>
);

window.addEventListener('online', () => repeatSending());

export default App;
