import {Checkpoint} from "../models/checkpoint";
import {LngLat} from "../models/track-point";

const SEARCH_RADIUS = 1200;

/**
 * Performs a quick search for checkpoints that are online and within a specified distance from a center point.
 *
 * @param {LngLat} center - The center point represented by latitude and longitude coordinates.
 * @param {Checkpoint[]} [checkpoints] - An optional array of Checkpoint objects to filter.
 * If not provided, the function will return an empty array if the center is not defined.
 * @returns {Checkpoint[]} An array of Checkpoint objects that are online and within SEARCH_RADIUS
 * from the center point. Each checkpoint in the returned array includes a `delta` property indicating the distance
 * from the center.
 *
 * @example
 * const center = { lat: 34.0522, lng: -118.2437 }; // Los Angeles coordinates
 * const checkpoints = [
 *     { coordinates: { lat: 34.0522, lng: -118.2437 }, isOnline: true },
 *     { coordinates: { lat: 35.0522, lng: -119.2437 }, isOnline: false }
 * ];
 * const result = quickSearch(center, checkpoints);
 * console.log(result); // Outputs the online checkpoints within SEARCH_RADIUS of the center
 */
export function quickSearch(center: LngLat, checkpoints?: Checkpoint[]): Checkpoint[] {
    return !center ? [] : checkpoints?.filter((cp: Checkpoint) => Checkpoint.prototype
        .isOnline.call(cp, new Date()))
        .map((point: Checkpoint) => ({
            ...point,
            delta: geoDistance(point.coordinates?.lat || 0,
                point.coordinates?.lng || 0,
                center.lat, center.lng)
        } as Checkpoint))
        .filter((point: Checkpoint) => SEARCH_RADIUS > (point.delta || Infinity));
}

/**
 * Calculates the geographical distance between two points on the Earth's surface.
 *
 * This function takes the latitude and longitude of two points and computes the distance between them in meters.
 * The Haversine formula accounts for the curvature of the Earth, providing an accurate distance measurement.
 *
 * @param {number} lat1 - Latitude of the first point in decimal degrees (e.g., 34.0522).
 * @param {number} lon1 - Longitude of the first point in decimal degrees (e.g., -118.2437).
 * @param {number} lat2 - Latitude of the second point in decimal degrees (e.g., 35.0522).
 * @param {number} lon2 - Longitude of the second point in decimal degrees (e.g., -119.2437).
 *
 * @returns {number} The distance between the two points in meters. The result is a positive number representing
 * the shortest path over the Earth's surface.
 *
 * @example
 * const distance = geoDistance(34.0522, -118.2437, 35.0522, -119.2437);
 * console.log(distance); // Outputs the distance in meters between the two coordinates
 */
export const geoDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    }
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const R = 6371e3;
    return Math.acos(Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)) * R;
};
