/**
 * Represents the different progress states for an event.
 */
export enum EProgress {
    Watch = "watch",
    Logged = "logged",
    Registered = "registered",
    Control = "control",
    Finish = "finish",
    DNF = "dnf"
}

/**
 * Represents the user's progress in the {@link Brevet}.
 *
 * @interface IProgress
 * @property {EProgress} status - The current status of the progress
 * @property {number} [distance] - The distance covered so far in km.
 * @property {string} [checkpointUid] - The current {@link Checkpoint} id.
 */
interface IProgress {
    status: EProgress;
    distance?: number;
    checkpointUid?: string;

    /**
     * Changes the progress to the given values.
     * @param {EProgress} progress - The new progress status.
     * @param {number} [distance] - The new distance or progress value (optional).
     * @param {string} [checkpointUid] - The unique identifier of the new checkpoint (optional).
     * @returns {Progress}  A new Progress object with the updated values.  This suggests
     *                     the IProgress interface is intended to be implemented by a concrete
     *                     `Progress` class and this method returns an instance of that class.
     */
    change(progress: EProgress, distance?: number, checkpointUid?: string): Progress;
}

/**
 * Creates a dictionary type where keys are members of an enum-like type (string, symbol, or number)
 * and values are of a specified type. This is similar to a regular object type, but it enforces
 * that the keys must be members of the provided enum-like type `T`.
 *
 * @template T The enum-like type whose members will be used as keys.  Must be string, symbol, or number.
 * @template U The type of the values in the dictionary.
 */
type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

/**
 * Defines a state machine as the transitions between different progress states.
 * This is represented as a dictionary where the key is the current progress state,
 * and the value is an array of possible next progress states.
 *
 * @type {EnumDictionary<EProgress, EProgress[]>}
 */
const progressTree: EnumDictionary<EProgress, EProgress[]> = {
    [EProgress.Watch]: [EProgress.Logged],
    [EProgress.Logged]: [EProgress.Registered, EProgress.Control],
    [EProgress.Registered]: [EProgress.Control],
    [EProgress.Control]: [EProgress.Control, EProgress.Finish, EProgress.DNF],
    [EProgress.Finish]: [],
    [EProgress.DNF]: []
}

/**
 * Stores progress information for different users.
 *
 * This type defines a dictionary where:
 * - The keys are user IDs (UIDs) represented as strings.
 * - The values are {@link IProgress} objects, containing the progress details for each user.
 */
export type ProgressStorage = {
    [uid: string]: IProgress
}

/**
 * Represents the progress of the user's brevet.
 * @property {EProgress} status The current status of the progress.
 * @property {number} [distance] The distance, if applicable, related to the progress.
 * @property {string} [checkpointUid] The unique identifier of a checkpoint, if applicable.
 */
export class Progress implements IProgress {
    status: EProgress;
    distance?: number;
    checkpointUid?: string;

    /**
     * Creates a new Progress instance.
     * @param status The initial status of the progress. Defaults to `EProgress.Watch`.
     * @param distance The initial distance, if applicable.
     * @param checkpointUid The initial checkpoint UID, if applicable.
     */
    constructor(status: EProgress = EProgress.Watch, distance?: number, checkpointUid?: string) {
        this.status = status;
        this.distance = distance;
        this.checkpointUid = checkpointUid;
    }

    /**
     * Creates a {@link Progress} instance from an {@link IProgress} document.
     * @param {IProgress} doc The IProgress document to create the instance from.
     * @returns A new {@link Progress} instance or null if the document is undefined.
     */
    static fromDoc(doc?: IProgress) {
        if (!doc) {
            return null;
        }
        return new Progress(doc.status, doc.distance)
    }

    /**
     * Changes the progress to a new state.
     * @param status The new status of the progress.
     * @param distance The new distance, if applicable.
     * @param checkpointUid The new checkpoint UID, if applicable.
     * @returns A new {@link Progress} instance with the updated values.
     * @throws {Error} If the status transition is not allowed according to the `progressTree`.
     */
    change(status: EProgress, distance?: number, checkpointUid?: string): Progress {
        const next = progressTree[this.status]
        if (!next.find(s => status === s)) {
            throw new Error(`Not allowed status ${status}`)
        }
        return new Progress(status, distance, checkpointUid);
    }
}

/**
 * Loads progress data from storage, converting plain objects to `Progress` instances.
 *
 * Iterates through the provided storage object and converts each value associated with a user ID (uid)
 * into a `Progress` object using `Progress.fromDoc()`.  This effectively hydrates the stored progress data
 * into usable `Progress` instances.
 *
 * @param storage An object representing the stored progress data, where keys are user IDs (uids) and
 *                values are either plain objects representing progress data or already instantiated `Progress` objects.
 * @returns The same storage object, but with plain object progress data replaced by `Progress` instances.
 */
export function loadProgress(storage: ProgressStorage): ProgressStorage {
    for (const uid in storage) {
        storage[uid] = Progress.fromDoc(storage[uid])
    }
    return storage;
}
