import {EBarcodeSource, TBarcode} from "../../models/barcode";
import {TBrevetFull, TBrevetShort} from "../../models/brevet";
import {NONE_CHECKPOINT, TCheckpoint} from "../../models/checkpoint";
import {RiderPrivateDetails, RiderPublicDetails} from "../../models/rider";
import {TResults, TRiderCheckIn} from "../../models/rider-checkin";
import {TRefreshTokens} from "../../models/auth";
import {refreshTokens} from "../auth";
import {expandDocuments, expandFields, expandResponse} from "../../utils/fetch-response";

import {documentToJson} from "./document-to-json";
import {jsonToDocument} from "./json-to-document";

import environment from "../../environment";

const BASE_URL = `${environment.FIRESTORE_URL}/v1/projects/${environment.PROJECT_ID}/databases/(default)/documents`


/**
 * Fetches the public details of a rider by their unique identifier (UID).
 *
 * This function makes an HTTP GET request to retrieve the rider's details from a specified
 * base URL. The response is then converted from the Firestore document format into a standard JavaScript object.
 *
 * @param {string} uid - The unique identifier of the rider whose details are to be fetched.
 * @returns {Promise<RiderPublicDetails>} - A promise that resolves to an object containing
 *                                          the public details of the rider {@link RiderPublicDetails}.
 *
 * @example
 * getRider('12345')
 *   .then(riderDetails => {
 *     console.log(riderDetails);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching rider details:', error);
 *   });
 */
export function getRider(uid: string): Promise<RiderPublicDetails> {
    return fetch(`${BASE_URL}/riders/${uid}`)
        .then(expandFields)
        .then(documentToJson);
}

/**
 * Fetches the private details of a rider by their unique identifier (UID) with authorization.
 *
 * This function makes an HTTP GET request to retrieve the private details of a rider from a
 * specified base URL. The request includes an authorization token in the headers to ensure
 * that only authorized users can access the private information. The response is then converted
 * from the Firestore document format into a standard JavaScript object.
 *
 * @param {string} uid - The unique identifier of the rider whose private details are to be fetched.
 * @param {string} token - The authorization token used to authenticate the request.
 * @returns {Promise<RiderPrivateDetails>} - A promise that resolves to an object containing
 *                                            the private details of the rider {@link RiderPrivateDetails}.
 *
 * @example
 * getPrivateRider('12345', 'your-auth-token')
 *   .then(riderPrivateDetails => {
 *     console.log(riderPrivateDetails);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching private rider details:', error);
 *   });
 */
export function getPrivateRider(uid: string, token: string): Promise<RiderPrivateDetails> {
    return fetch(`${BASE_URL}/private/${uid}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(expandFields)
        .then(documentToJson);
}

/**
 * Fetches a list of brevets from the server.
 *
 * This function makes an HTTP GET request to retrieve a list of brevets from a specified
 * base URL. The response is then converted from the Firestore document format into a standard
 * JavaScript object. Finally, it extracts and returns the list of brevets from
 * the resulting data.
 *
 * @returns {Promise<TBrevetShort[]>} - A promise that resolves to an array of brevets,
 *                                       each represented as a short summary object {@link TBrevetShort}.
 *
 * @example
 * getBrevetList()
 *   .then(brevetList => {
 *     console.log('Fetched brevets:', brevetList);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching brevet list:', error);
 *   });
 */
export function getBrevetList(): Promise<TBrevetShort[]> {
    return fetch(`${BASE_URL}/brevets/list`)
        .then(expandFields)
        .then(documentToJson)
        .then(data => data.brevets)
}

/**
 * Fetches detailed information about a specific brevet by its unique identifier (UID).
 *
 * This function makes an HTTP GET request to retrieve full details of a brevet from a
 * specified base URL using the provided UID. The response is then converted from the Firestore
 * document format into a standard JavaScript object.
 *
 * @param {string} uid - The unique identifier of the brevet whose details are to be fetched.
 * @returns {Promise<TBrevetFull>} - A promise that resolves to an object containing
 *                                    the full details of the specified brevet {@link TBrevetFull}
 *
 * @example
 * getBrevetInfo('abc123')
 *   .then(brevetDetails => {
 *     console.log('Fetched brevet details:', brevetDetails);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching brevet info:', error);
 *   });
 */
export function getBrevetInfo(uid: string): Promise<TBrevetFull> {
    return fetch(`${BASE_URL}/brevets/${uid}`)
        .then(expandFields)
        .then(documentToJson)
}

/**
 * Fetches a list of checkpoints for a specific brevet by its unique identifier (UID).
 *
 * This function makes an HTTP GET request to retrieve the checkpoints associated with a
 * brevet from a specified base URL, ordering them by distance. The response is processed
 * to expand any necessary documents and then maps each checkpoint to convert its fields
 * into a standard JavaScript object.
 *
 * @param {string} uid - The unique identifier of the brevet whose checkpoints are to be fetched.
 * @returns {Promise<TCheckpoint[]>} - A promise that resolves to an array of checkpoints,
 *                                      each represented as a {@link TCheckpoint}.
 *
 * @example
 * getBrevetCheckpoints('abc123')
 *   .then(checkpoints => {
 *     console.log('Fetched checkpoints:', checkpoints);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching brevet checkpoints:', error);
 *   });
 */
export function getBrevetCheckpoints(uid: string): Promise<TCheckpoint[]> {
    return fetch(`${BASE_URL}/brevets/${uid}/checkpoints?orderBy=distance`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
}

/**
 * Fetches a list of rider check-ins at a specific checkpoint for a given brevet.
 *
 * This function makes an HTTP GET request to retrieve the riders who have checked in at
 * a specified checkpoint associated with a brevet. The response is processed to expand
 * any necessary documents, and each rider's data is transformed to include the rider's
 * unique identifier (UID) along with the checkpoint UID.
 *
 * @param {string} brevetUid - The unique identifier of the brevet containing the checkpoint.
 * @param {string} checkpointUid - The unique identifier of the checkpoint for which to fetch rider check-ins.
 * @returns {Promise<TRiderCheckIn[]>} - A promise that resolves to an array of rider check-in objects {@link TRiderCheckIn},
 *                                         each containing details about the rider and the checkpoint.
 *
 * @example
 * getCheckpointCheckins('brevet123', 'checkpoint456')
 *   .then(checkins => {
 *     console.log('Fetched rider check-ins:', checkins);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching checkpoint check-ins:', error);
 *   });
 */
export function getCheckpointCheckins(brevetUid: string, checkpointUid: string): Promise<TRiderCheckIn[]> {

    return fetch(`${BASE_URL}/brevets/${brevetUid}/checkpoints/${checkpointUid}/riders`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
        .then(riders => riders.map(({uid, ...rest}) => ({...rest, riderUid: uid, checkpointUid})))
}

/**
 * Fetches and aggregates rider check-in data for all checkpoints associated with a specific brevet.
 *
 * This function retrieves the list of checkpoints for a given brevet using its unique identifier (UID).
 * It then fetches the check-in data for each checkpoint and aggregates the results into a single object.
 * The final output maps each rider's unique identifier to their corresponding check-in details, including
 * the time they checked in at each checkpoint.
 *
 * @param {string} uid - The unique identifier of the brevet for which to fetch rider check-ins.
 * @returns {Promise<TResults>} - A promise that resolves to an object {@link TResults} containing aggregated
 *                                check-in data for all riders, where each key is a rider's unique identifier
 *                                and the value contains their check-in details.
 *
 * @example
 * getBrevetCheckins('brevet123')
 *   .then(checkinData => {
 *     console.log('Fetched brevet check-ins:', checkinData);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching brevet check-ins:', error);
 *   });
 */
export function getBrevetCheckins(uid: string): Promise<TResults> {
    const checkpointIndices = {};
    // TODO: cache checkpoints, they won't change
    return getBrevetCheckpoints(uid)
        .then((checkpoints: TCheckpoint[]) => {
            checkpoints.map((cp, index) => checkpointIndices[cp.uid] = index);
            return checkpoints;
        })
        .then((checkpoints: TCheckpoint[]) => Promise.all(checkpoints.map(cp => getCheckpointCheckins(uid, cp.uid))))
        .then((riders: TRiderCheckIn[][]) => riders.reduce(
            (dict, riders) => {
                riders.forEach(rider => {
                    if (!dict[rider.riderUid]) {
                        dict[rider.riderUid] = rider;
                    }
                    const time = dict[rider.riderUid].time;
                    time[checkpointIndices[rider.checkpointUid]] = rider.time[0]
                    dict[rider.riderUid] = {
                        ...rider,
                        time,
                    }
                })
                return dict;
            }, {} as TResults),
        )
}

/**
 * Fetches a list of checkpoints from the server.
 *
 * This function makes an HTTP GET request to retrieve all available checkpoints from a
 * specified base URL. The response is processed to expand any necessary documents, and
 * each checkpoint's data is transformed into a standard JavaScript object.
 *
 * @returns {Promise<TCheckpoint[]>} - A promise that resolves to an array of checkpoint objects,
 *                                      each represented as a {@link TCheckpoint}.
 *
 * @example
 * getCheckpointList()
 *   .then(checkpointList => {
 *     console.log('Fetched checkpoints:', checkpointList);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching checkpoint list:', error);
 *   });
 */
export function getCheckpointList(): Promise<TCheckpoint[]> {
    return fetch(`${BASE_URL}/checkpoints`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
}

/**
 * Fetches detailed information about a specific checkpoint by its unique identifier (UID).
 *
 * This function makes an HTTP GET request to retrieve full details of a checkpoint from a
 * specified base URL using the provided UID. The response is processed to expand any necessary
 * fields and convert the Firestore document format into a standard JavaScript object.
 *
 * @param {string} uid - The unique identifier of the checkpoint whose details are to be fetched.
 * @returns {Promise<TCheckpoint>} - A promise that resolves to an object containing
 *                                    the full details of the specified checkpoint {@link TCheckpoint}.
 *
 * @example
 * getCheckpointInfo('checkpoint123')
 *   .then(checkpointDetails => {
 *     console.log('Fetched checkpoint details:', checkpointDetails);
 *   })
 *   .catch(error => {
 *     console.error('Error fetching checkpoint info:', error);
 *   });
 */
export function getCheckpointInfo(uid: string): Promise<TCheckpoint> {
    return fetch(`${BASE_URL}/checkpoints/${uid}`)
        .then(expandFields)
        .then(documentToJson)
}

/**
 * Creates a barcode entry in the database for a specified control point.
 *
 * This function generates a barcode by making an authenticated POST request to the server.
 * It first checks for a valid authentication token and attempts to refresh it if necessary.
 * The barcode data, along with the control UID and owner information, is then formatted and sent
 * to the server. Upon successful creation, the function returns the unique identifier (UID) of the
 * newly created barcode entry.
 *
 * @param {EBarcodeSource} root - The source of the barcode creation - `riders` or `checkpoints.
 * @param {string} [controlUid=NONE_CHECKPOINT] - The unique identifier of the control point associated
 * with the barcode.
 * @param {TBarcode} barcode - The barcode data to be created {@link TBarcode}.
 * @param {string} token - The authentication token used for authorization.
 * @returns {Promise<string>} - A promise that resolves to the UID of the created barcode entry.
 *
 * @throws {Promise<string>} - Rejects with an error message if the token is invalid or if there is an error during the process.
 *
 * @example
 * createBarcode('riders', 'checkpoint123', { code: '1234567890' }, 'your-auth-token')
 *   .then(barcodeUid => {
 *     console.log('Created barcode with UID:', barcodeUid);
 *   })
 *   .catch(error => {
 *     console.error('Error creating barcode:', error);
 *   });
 */
export function createBarcode(
    root: EBarcodeSource,
    controlUid: string = NONE_CHECKPOINT,
    barcode: TBarcode,
    token: string,
): Promise<string> {
    if (!token) {
        return Promise.reject('No authentication token');
    }

    return refreshTokens(token)
        .then((json: TRefreshTokens) => [json.id_token, json.user_id])
        .catch(error => {
            console.error("Token refresh error", error)
            throw error;
        })
        .then(([idToken, localId]) => [
            idToken,
            JSON.stringify(
                jsonToDocument({
                ...barcode,
                control: controlUid,
                owner: localId || controlUid
            }).mapValue)
        ])
        .then(([idToken, payload]) => fetch(`${BASE_URL}/${root}/${controlUid}/barcodes`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            body: payload
        }))
        .then(expandResponse)
        .then(response => {
            const uid = response.name.split('barcodes/')[1];
            return {...response.fields, uid};
        })
        .then(data => data.uid)
        /*
        TODO: enable ?
        .then(docRef => {
            barcode.uid = docRef.id;
            return updateDoc(docRef, {uid: docRef.id})
                .then(() => docRef.id);
        })
        */
        .catch(error => {
            console.error('Error adding document: ', error);
            return Promise.reject(error);
        });
}
