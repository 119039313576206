import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from 'muicss/lib/react/input';
import {Fragment, h} from 'preact';
import {useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {Provider} from "../../models/provider";
import {checkEmail} from "../../services/auth";

import style from './style.scss';

/**
 * The EmailLoginDialog properties
 * @property {Signal<EmailFlow>} state - The EmailFlow state signal.
 */
type EmailLoginDialogProps = {
    state: Signal<EmailFlow>;
}

/**
 * Represents the state of the EmailLoginDialog.
 *
 * @property {string} email - The email address entered by the user.
 * @property {boolean} error - Indicates whether an error occurred during the login process.
 */
type EmailLoginDialogState = {
    email: string;
    error: boolean;
}

/**
 * A dialog component for email-based login or account creation.
 *
 * This component allows users to enter their email address. It then checks if the
 * email is already registered. Based on the registration status, it either
 * proceeds to password input, informs the user about existing provider login,
 * or initiates account creation.
 *
 * @param {EmailLoginDialogProps} props - The component's props.
 * @param {Signal<EmailFlow>} props.state - The {@link EmailFlow} state signal.
 * @returns {JSX.Element} The rendered email login dialog.
 */
const EmailLoginDialog = ({state}: EmailLoginDialogProps): JSX.Element => {
    const {t} = useTranslation();
    const [credentials, setState] = useState<EmailLoginDialogState>({email: state.value.email, error: false});

    /**
     * Updates the email address in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object triggered by the input field.
     * @returns {void}
     */
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    /**
     * Updates the error state within the component.
     *
     * @param {boolean} state - The error flag indicating an issue.
     * @returns {void}
     */
    const updateError = (state: boolean) => setState({...credentials, error: state});

    /**
     * Submits the provided email address for login or account creation.
     *
     * Checks if the email is registered and handles different scenarios:
     * - If registered with a password: proceeds to the password input step.
     * - If registered with a provider (e.g., Google, Facebook): informs the user.
     * - If not registered: proceeds to the account creation step.
     *
     * Handles invalid email format by displaying an error message.
     */
    const submitEmail = () => {
        if (!credentials.email) {
            return;
        }
        // reset the error state
        updateError(false);
        // search for the account
        checkEmail(credentials.email)
            .then(info => {
                if (info.registered) {
                    if (info.signinMethods?.includes('password')) {
                        // ask for a password
                        state.value = new EmailFlow(EMailLogin.EmailOk, {email: credentials.email})
                    } else {
                        // the account is taken by a provider
                        state.value = new EmailFlow(EMailLogin.EmailUsed, {
                            email: credentials.email,
                            // fall back to the Baltic star
                            provider: info.signinMethods?.[0] as Provider || Provider.BalticStar
                        })
                    }
                } else {
                    // not registered yet
                    state.value = new EmailFlow(EMailLogin.CreateAccount, {email: credentials.email})
                }
            })
            .catch(error => {
                console.error("error", error);
                if (error.message === 'INVALID_IDENTIFIER') {
                    updateError(true);
                }
            })
    }

    return (
        <>
            <h2>{t("emailLogin.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("emailLogin.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("emailLogin.incorrect")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("emailLogin.cancel")}</Button>
                <Button onClick={submitEmail} variant="raised" color="primary">{t("emailLogin.next")}</Button>
            </div>
        </>
    )
}

export default EmailLoginDialog;
