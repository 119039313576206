import {Checkpoint, TCheckpoint} from "./checkpoint";
import {LngLat, TrackPoint} from "./track-point";

export const NONE_BREVET = 'none';

/**
 * Short brevet description with essential attributes for the brevet list item:
 *
 * - uid: unique identifier
 * - name: brevet title
 * - length: distance in km
 * - startDate: when to start
 * - endDate: optional last time to finish
 */
export type TBrevetShort = {
  uid: string;
  name: string;
  length: number;
  startDate: Date|string;
  endDate?: Date|string;
}

/**
 * Full brevet description extending the {@link TBrevetShort} with additional attributes:
 *
 * - mapUrl: optional URL of the map for the brevet route
 * - startPoint: optional coordinates of the start point
 * - track: optional array of TrackPoints for the route
 * - checkpoints: optional array of TCheckpoint or Checkpoint
 * - results: optional results of the brevet
 */
export type TBrevetFull = TBrevetShort & {
  mapUrl?: string;
  startPoint?: LngLat;
  track?: TrackPoint[];
  checkpoints?: (TCheckpoint | Checkpoint)[];
  results?: unknown;
}

/**
 * Brevet class representing a brevet event with its properties.
 *
 * This class implements {@link TBrevetFull}, providing a comprehensive
 * set of attributes to describe a brevet event. It includes essential information
 * such as unique identifier, title, distance, and start and end dates, along with
 * additional details like map URL, start point coordinates, track points, checkpoints,
 * and results.
 */
export class Brevet implements TBrevetFull {
  uid: string;
  name: string;
  length: number;
  startDate: Date;
  endDate?: Date;

  mapUrl?: string;
  startPoint?: LngLat;
  track?: TrackPoint[];
  checkpoints?: Checkpoint[];
  results?: unknown;

  /**
   * Creates a new Brevet.
   * @param startDate The start date of the brevet.
   * @param endDate The end date of the brevet (optional).
   */
  constructor(startDate: Date, endDate: Date = null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  /**
   * Creates an instance of the Brevet class using the provided document (mapping).
   * Converts {@link Date} and {@link Checkpoint}
   *
   * @param doc - A document containing brevet details.
   * @returns An instance of the Brevet class.
   */
  static fromDoc(doc: TBrevetFull) {
    const {startDate, endDate, checkpoints, ...rest} = doc;
    // inflate the dates
    const brevet = new Brevet(new Date(startDate), endDate ? new Date(endDate) : null);
    brevet.checkpoints = checkpoints?.map((cp: TCheckpoint) => Checkpoint.fromDoc(cp));
    Object.assign(brevet, rest);
    return brevet;
  }

  /**
   * Check if the brevet has started: the startDate is missing or in the past.
   *
   * @returns {boolean} `true` if the brevet has started, `false` otherwise.
   */
  get hasStarted(): boolean {
    const now: Date = new Date();
    return (!this.startDate || this.startDate <= now);
  }

  /**
   * Check if the brevet has ended: the endDate is set and in the past.
   *
   * @returns {boolean} `true` if the brevet has ended, `false` otherwise.
   */
  get hasEnded(): boolean {
    const now: Date = new Date();
    return (!!this.endDate && this.endDate < now);
  }

  /**
   * Check if the brevet is currently online: the brevet has started, but hasn't ended yet.
   *
   * @returns {boolean} `true` if the brevet is online, `false` otherwise.
   */
  get isOnline(): boolean {
    return this.hasStarted && !this.hasEnded;
  }

  /**
   * Check if the brevet has route information available: either the track is set with at least one point
   * or there are at least one checkpoint.
   *
   * @returns {boolean} `true` if the brevet has route information, `false` otherwise.
   */
  mapAvailable(): boolean {
    return (this.track instanceof Array && this.track.length > 0)
        || (this.checkpoints instanceof Array && this.checkpoints.length > 0)
  }
}
