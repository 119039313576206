import sha256 from '@cryptography/sha256';
import querystring from "querystring";

import environment from "../../environment";
import {
    TAuth,
    TAuthEmail,
    TBalticStarAuth,
    TBalticStarUserInfo,
    TEmailInfo,
    Tokens,
    TRefreshTokens
} from "../../models/auth";
import {Provider, ProviderOptions, providerOptions} from "../../models/provider";
import {expandResponse} from "../../utils/fetch-response";

const IDENTITY_TOOLKIT_URL = 'https://identitytoolkit.googleapis.com/v1/accounts';
const SECURE_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${environment.GOOGLE_APP_ID}`;
const BALTIC_STAR_URL = "https://auth.balticstar.spb.ru"

/**
 * Generates a Google Identity Toolkit URL given a command.
 *
 * @param command - The command to use in the URL (e.g. 'signInWithPassword', 'signUp', etc.)
 * @returns The generated URL.
 */
const identityEndpoint = (command: string): string => `${IDENTITY_TOOLKIT_URL}:${command}?key=${environment.GOOGLE_APP_ID}`

/**
 * The URL that the user will be redirected to after an OAuth login flow.
 *
 * The value is set to the current URL, but with the path changed to '/login'.
 *
 * @example
 * If the current URL is "https://example.com/path/to/page", the value will be
 * "https://example.com/login"
 *
 * @type {string}
 */
// eslint-disable-next-line prefer-template
const LOGIN_URL = window.location.href.split('/').slice(0,3).join('/') + '/login';

/**
 * Redirects the user to the authorization URL for the given provider.
 *
 * This function takes the provider ID and the state that should be sent to the
 * provider. The state is used to prevent CSRF attacks, and is checked when the
 * user is redirected back to this application. The state should be a unique
 * value that is difficult to guess. See also provider-specific options in {@link providerOptions}.
 *
 * The function returns nothing, but redirects the user to the authorization URL
 * for the given provider.
 *
 * @param providerId - The ID of the provider to use (e.g. 'google.com', 'facebook.com', etc.)
 * @param state - The state that should be sent to the provider.
 */
export function oauthRedirect(providerId: string, state: string) {
    const {baseUrl, ...customOptions} = providerOptions[providerId];

    const defaultOptions: ProviderOptions = {
        redirect_uri: LOGIN_URL,
        nonce: sha256(state, 'hex'),
        state,
    }

    Object.assign(defaultOptions, customOptions)
    const params = querystring.stringify(defaultOptions);
    window.location.href = `${baseUrl}?${params}`;
}

/**
 * Parses the URL fragment to extract OAuth tokens.
 *
 * This function retrieves the portion of the URL following the hash ("#")
 * symbol and parses it to extract OAuth tokens. The extracted tokens are
 * returned as an object of type {@link Tokens}.
 *
 * @returns The parsed tokens from the URL fragment.
 */
export const urlTokens = (): Tokens => querystring.parse(window.location.href.split("#")[1]) as unknown as Tokens;

/**
 * Constructs a JSON payload for identity-related requests.
 *
 * This function takes OAuth tokens and a provider ID to assemble a payload
 * used in identity requests. The payload includes either an access token
 * or ID token, along with a nonce derived from the token's state.
 * The resulting payload is then converted into a JSON string containing
 * parameters necessary for identity operations.
 *
 * @param tokens - The OAuth {@link Tokens} object containing `access_token`, `id_token`, and `state`.
 * @param providerId - The ID of the identity provider (e.g., 'google.com', 'facebook.com').
 * @returns A JSON string representing the identity payload for further processing.
 */
export function identityPayload(tokens: Tokens, providerId: string): string {
    const {access_token, id_token, state} = tokens;
    const payload = {
        access_token,
        id_token,
        providerId,
        nonce: state
    }

    for (const name of ["access_token", "id_token"]) {
        if (!payload[name]) {
            delete payload[name]
        }
    }

    return JSON.stringify({
        postBody: querystring.stringify(payload),
        requestUri: LOGIN_URL,
        returnIdpCredential: true,
        returnSecureToken: true,
        returnRefreshToken: true,
    })
}

function identityPayloadCustom(tokens: Tokens) {
    const {access_token} = tokens;
    const payload = {
        token: access_token,
        returnSecureToken: true,
    }

    return JSON.stringify(payload)
}

function convertBalticStarUserInfo(info: TBalticStarUserInfo): TBalticStarAuth {
    const {family_name, given_name, nickname, name, uid, sub, ...rest} = info;
    return {
        ...rest,
        firstName: given_name,
        lastName: family_name,
        nickName: nickname,
        displayName: name,
        fullName: name,
        localId: uid,
        federatedId: `${Provider.BalticStar}/${sub}`,
    } as unknown as TBalticStarAuth
}

/**
 * Queries the identity provider to authenticate a user.
 *
 * This function creates a JSON payload using {@link identityPayload} and
 * sends it to the identity provider to authenticate the user. The response
 * is then parsed and returned as a {@link TAuth} object.
 *
 * @param tokens - The OAuth {@link Tokens} object containing `access_token`, `id_token`, and `state`.
 * @param providerId - The ID of the identity provider (e.g., 'google.com', 'facebook.com').
 * @returns A promise that resolves to a {@link TAuth} object when the
 * authentication is successful.
 */
export function queryIdentityApi(tokens: Tokens, providerId: string): Promise<TAuth> {
    const payload = identityPayload(tokens, providerId);
    return providerId === Provider.BalticStar ?
        Promise.all([
            fetch(`${BALTIC_STAR_URL}/userinfo`, {
                headers: {
                    //Authorization: 'basic ' + btoa(environment.BALTIC_STAR_CLIENT_ID),
                    Authorization: `${tokens.token_type} ${tokens.access_token}`
                },
            })
                .then(expandResponse)
                .then((info: TBalticStarUserInfo) => convertBalticStarUserInfo(info)),
            fetch(identityEndpoint('signInWithCustomToken'), {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: identityPayloadCustom(tokens)
            }).then(expandResponse)
        ]).then(([userinfo, tokens]) => Object.assign({}, userinfo, tokens)) :
        fetch(identityEndpoint('signInWithIdp'), {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: payload
        }).then(expandResponse);
}

/**
 * Creates a URL query string payload for the token refresh endpoint.
 *
 * @param refresh_token - The refresh token to pass to the endpoint.
 * @returns A URL query string containing the refresh token and the grant type.
 */
function tokenPayload(refresh_token: string) {
    return querystring.stringify({
        refresh_token,
        grant_type: 'refresh_token'
    })
}

/**
 * Refreshes a user's access token using a refresh token.
 *
 * This function takes a refresh token and sends it to the token endpoint to
 * obtain a new access token. The response is then parsed and returned as a
 * {@link TRefreshTokens} object.
 *
 * @param token - The refresh token to use for the request.
 * @returns A promise that resolves to a {@link TRefreshTokens} object containing
 * the new access token.
 */
export function refreshTokens(token: string): Promise<TRefreshTokens> {
    const payload = tokenPayload(token)
    return fetch(SECURE_TOKEN_URL, {
        method: 'POST',
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: payload
    }).then(expandResponse)
}

/**
 * Verifies if an email is associated with an existing account.
 *
 * This function sends a POST request to the identity provider to check if
 * the provided email is linked to an account. The request includes the email
 * as an identifier and the current login URL to continue the authentication flow.
 * It returns a promise that resolves to a {@link TEmailInfo} object containing
 * information about the email, such as the registered status and available sign-in methods.
 *
 * @param email - The email address to check for association with an existing account.
 * @returns A promise that resolves to a {@link TEmailInfo} object with details about the email.
 */
export function checkEmail(email: string): Promise<TEmailInfo> {
    const payload = JSON.stringify({
        identifier: email,
        continueUri: LOGIN_URL,
    });
    return fetch(identityEndpoint('createAuthUri'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

/**
 * Signs in to an existing account using an email and password.
 *
 * This function takes an email and password and sends a POST request to the
 * identity provider to authenticate the user. The request includes as well as a flag
 * to return a secure token. The response is
 * then parsed and returned as a {@link TAuthEmail} object.
 *
 * @param email - The email address to sign in with.
 * @param password - The password to sign in with.
 * @returns A promise that resolves to a {@link TAuthEmail} object containing the authentication
 * information.
 */
export function signInEmail(email: string, password: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,

        email,
        password
    });
    return fetch(identityEndpoint('signInWithPassword'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

/**
 * Signs up a new user with an email and password.
 *
 * This function sends a POST request to the identity provider to create
 * a new account using the provided email and password. The request
 * includes a flag to return a secure token upon successful account creation.
 * The response is parsed and returned as a {@link TAuthEmail} object
 * containing the authentication information for the newly created account.
 *
 * @param email - The email address to sign up with.
 * @param password - The password to sign up with.
 * @returns A promise that resolves to a {@link TAuthEmail} object containing
 * the authentication information for the new user.
 */
export function signUpEmail(email: string, password: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,
        email,
        password
    });
    return fetch(identityEndpoint('signUp'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

/**
 * Updates a user's display name.
 *
 * This function sends a POST request to the identity provider to update a user's
 * display name using the provided ID token and display name. The request
 * includes a flag to return a secure token upon successful update.
 * The response is parsed and returned as a {@link TAuthEmail} object
 * containing the updated authentication information for the user.
 *
 * @param idToken - The ID token to use for the request.
 * @param displayName - The new display name to update to.
 * @returns A promise that resolves to a {@link TAuthEmail} object containing
 * the updated authentication information for the user.
 */
export function updateInfo(idToken: string, displayName: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,
        idToken,
        displayName
    });
    return fetch(identityEndpoint('update'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

/**
 * Sends a password reset email to a user.
 *
 * This function takes an email address and sends a POST request to the
 * identity provider to send a password reset email. The request includes
 * the email address and the type of request - PASSWORD_RESET. The response is then parsed
 * and returned as a {@link TEmailInfo} object.
 *
 * @param email - The email address to send the password reset email to.
 * @returns A promise that resolves to a {@link TEmailInfo} object containing
 * the result of the request.
 */
export function sendRecover(email: string): Promise<TEmailInfo> {
    const payload = JSON.stringify({
        requestType: "PASSWORD_RESET",
        email
    });
    return fetch(identityEndpoint('sendOobCode'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}