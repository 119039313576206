import Button from "muicss/lib/react/button";
import {ComponentChildren, h} from "preact"
import {route} from "preact-router";

/**
 * The LinkButton properties
 * @property {string} to - The URL to link the button to.
 * @property {ComponentChildren} [children] - The nested elements.
 */
type LinkButtonProps = {
    to: string;
    children: ComponentChildren;
    // the rest of attributes
    [key: string]: unknown;
}

/**
 * A button component that navigates to a specified route when clicked.
 *
 * @param {object} props - The component's props.
 * @param {string} props.to - The route to navigate to.  This should be a path that `route()` understands.
 * @param {ReactNode} props.children - The content to be displayed within the button.
 * @param {object} props.rest - Any additional props to be passed to the underlying `Button` component.
 *  This allows for customization of the button's appearance and behavior (e.g., styling, disabled state).
 * @returns {JSX.Element} A button element that triggers navigation.
 */
const LinkButton = (props: LinkButtonProps): JSX.Element => {
    const {to, children, ...rest} = props;

    return (
        <Button {...rest} onClick={() => route(to)}>
            {children}
        </Button>
    )
}

export default LinkButton;
