import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {Link} from "preact-router";
import {useContext, useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {TAuthEmail} from "../../models/auth";
import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {signInEmail} from "../../services/auth";
import {State, Storage} from "../app-state";

import style from './style.scss';

/**
 * The EmailPasswordDialog properties
 * @property {Signal<EmailFlow>} state - The EmailFlow state signal.
 */
type EmailPasswordDialogProps = {
    state: Signal<EmailFlow>;
}

/**
 * Represents the state of the EmailPasswordDialog.
 *
 * @property {string} email - The user's email address.
 * @property {string} password - The user's password.
 * @property {boolean} error - Indicates whether an error occurred during login.
 */
type EmailPasswordDialogState = {
    email: string;
    password: string;
    error: boolean;
}

/**
 * Renders a dialog for email/password authentication.
 *
 * This component allows users to log in using their email and password.
 * It provides input fields for email and password, handles submission and validation,
 * and offers a password recovery option. It displays an error message if the
 * password doesn't match.
 *
 * @param {EmailPasswordDialogProps} props - The component's properties.
 * @param {Signal<EmailFlow>} props.state - The current state of the email login flow.
 * @returns {JSX.Element} The rendered email/password dialog.
 */
const EmailPasswordDialog = ({state}: EmailPasswordDialogProps): JSX.Element => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage)

    const [credentials, setState] = useState<EmailPasswordDialogState>({
        email: state.value.email, password: null, error: false
    });
    /**
     * Updates the email address in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object triggered by the input field.
     * @returns {void}
     */
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    /**
     * Updates the user password in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the input field.
     * @returns {void}
     */
    const updatePassword = (event) => setState({...credentials, password: event.target.value});
    /**
     * Updates the error state within the component.
     *
     * @param {boolean} state - The error flag indicating an issue.
     * @returns {void}
     */
    const updateError = (state: boolean) => setState({...credentials, error: state});

    /**
     * Submits the user's email and password for authentication.
     *
     * Checks if both email and password fields are filled. If not, the function returns early.
     * If both fields are present, it attempts to sign in the user using the provided credentials.
     * Updates the authentication state with the user information upon successful sign-in.
     * If the sign-in fails due to an invalid password, an error state is updated.
     * Any other sign-in errors are logged to the console.
     */
    const submitEmailPassword = () => {
        if (!credentials.email || !credentials.password) {
            return;
        }
        // reset the error state
        updateError(false);
        signInEmail(credentials.email, credentials.password)
            .then((info: TAuthEmail) => auth.value = {...info, providerId: 'password'})
            .catch(error => {
                console.error("error", error);
                if (error.message === 'INVALID_PASSWORD') {
                    updateError(true);
                }
            })
    }
    /**
     * Handles the password recovery process.
     *
     * This function is triggered when the user clicks the "Forgot Password" link.
     * It prevents the default form submission behavior and updates the application state
     * to initiate the password recovery flow.
     *
     * @param {React.SyntheticEvent} event The synthetic event triggered by the click.
     */
    const recoverPassword = (event) => {
        event.preventDefault();
        state.value = new EmailFlow(EMailLogin.RecoverPassword, {email: credentials.email})
    }

    return (
        <>
            <h2>{t("emailPassword.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("emailPassword.email")} value={credentials.email} onChange={updateEmail} />
            <Input type="password" name="password" minlength="6" required autocomplete="current-password"
                   floatingLabel={true}
                   label={t("emailPassword.password")} value={credentials.password} onChange={updatePassword} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("emailPassword.noMatch")}</div>

            <div className={style.table}>
                <div className={style.row}>
                    <div className={`${style.cell} mui--text-caption`}>
                        <Link href="#" onClick={recoverPassword}>{t("emailPassword.trouble")}</Link>
                    </div>
                    <div className={`${style.actions} ${style.cell}`}>
                        <Button onClick={submitEmailPassword} variant="raised"
                                color="primary">{t("emailPassword.title")}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailPasswordDialog;
