/**
 * Represents a geographical coordinate point. TODO: reuse Mapbox API
 * @property {number} lng - The longitude of the coordinate.
 * @property {number} lat - The latitude of the coordinate.
 */
export type TLngLat = {
    lng: number;
    lat: number;
};


/**
 * Represents a geographic coordinate with longitude and latitude.
 * Implements the {@link TLngLat} interface.
 */
export class LngLat implements TLngLat {
    lng: number;
    lat: number;

    /**
     * Creates a new `LngLat` instance.
     * @param {number} lng The longitude.
     * @param {number} lat The latitude.
     */
    constructor(lng: number, lat: number) {
        this.lng = lng;
        this.lat = lat;
    }
}


export type TrackPoint = {
    distance: number;
    coordinates: LngLat;
};
