type Mapping = {
    [key: string]: unknown;
}

/**
 * Processes a Fetch API `Response` object.
 *
 * This function attempts to parse the JSON body of the response. If the response
 * is successful (i.e., `response.ok` is true), it returns the parsed JSON.
 * Otherwise, it throws an error with a message from the JSON error object.
 *
 * @param response - The Fetch API `Response` object to process.
 * @returns A promise that resolves to the parsed JSON if the response is successful.
 * @throws Will throw an error if the response is not successful.
 */
export function expandResponse(response: Response) {
    return Promise.all([response, response.json()])
        .then(([response, json]) => {
            if (response.ok) {
                return json
            }
            throw new Error(json.error.message)
        })
}

/**
 * Extracts the `fields` property from a Fetch API `Response` object.
 *
 * This function processes the response using the {@link expandResponse} function
 * to parse the JSON body. It then retrieves the `fields` property from the parsed
 * JSON object.
 *
 * @param response - The Fetch API `Response` object to process.
 * @returns A promise that resolves to the `fields` property of the parsed JSON object.
 * @throws Will throw an error if the response is not successful.
 */
export function expandFields(response: Response): Promise<Mapping> {
    return expandResponse(response)
        .then(json => json.fields)
}

/**
 * Extracts the `documents` property from a Fetch API `Response` object.
 *
 * This function processes the response using the {@link expandResponse} function
 * to parse the JSON body. It then retrieves the `documents` property from the
 * parsed JSON object. The value of this property is an array of objects, where
 * each object represents a Firestore document.
 *
 * @param response - The Fetch API `Response` object to process.
 * @returns A promise that resolves to the `documents` property of the parsed JSON object.
 * @throws Will throw an error if the response is not successful.
 */
export function expandDocuments(response: Response): Promise<Mapping[]> {
    return expandResponse(response)
        .then(json => json.documents)
}