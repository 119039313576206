import Dropdown from 'muicss/lib/react/dropdown';
import DropdownItem from 'muicss/lib/react/dropdown-item';
import {Fragment, h} from 'preact';
import {useTranslation} from "react-i18next";

import {defaultLanguage, Language} from "../../../i18n";
import languages from "../../../i18n/languages.json";

import style from './style.scss';

/**
 * A functional component that renders a locale switcher.
 *
 * @returns The JSX element representing the locale switcher.
 */
const LocaleSwitcher = () => {
    const {t, i18n} = useTranslation();
    const languageList = languages as Language[];

    /**
     * Switches the current locale when a language item is clicked.
     *
     * @param event The event object passed to the event handler.
     * @returns {Promise}
     */
    const switchLocale = (event) => {
        const code = event.target.parentElement.lang || defaultLanguage
        return i18n.changeLanguage(code)
    }
    return (
        <div className={style.localeSwitcher}>
            <i className="material-icons">translate</i>
            <Dropdown
                variant="raised"
                alignMenu="right"
                label={<>
                    {t("localeSwitcher.language")}
                    <span className="mui-caret" />
                </>}>
                {languageList.map((language: Language) => (
                    <DropdownItem
                        key={language.code}
                        lang={language.code}
                        className={i18n.resolvedLanguage === language.code ? style.selected : null}
                        onClick={switchLocale}>{language.name}</DropdownItem>
                ))}
            </Dropdown>
        </div>
    );
}

export default LocaleSwitcher;
