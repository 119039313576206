/**
 * Enum of possible barcode source.
 * @enum {string}
 */
export enum EBarcodeSource {
    Rider = "riders",
    Checkpoint = "checkpoints",
}

/**
 * Represents a barcode scan.
 *
 * @interface TBarcode
 * @property {string} [uid] - Unique identifier for the barcode scan.
 * @property {Date | string} time - Timestamp of the barcode scan. Can be a Date object or a string representation.
 * @property {string} code - The actual barcode string.
 * @property {string} message - A message associated with the barcode scan.
 * @property {string} [control] - An optional control string.
 * @property {string} [owner] - An optional owner string.
 * @property {string} [name] - A meaningful control identifier.
 */
export interface TBarcode {
    uid?: string;
    time: Date|string;
    code: string;
    message: string;

    control?: string;
    owner?: string;

    // a meaningful control identifier
    name?: string;
}

/**
 * Represents a saved barcode.
 *
 * @property {EBarcodeSource} source - The source of the barcode (e.g., riders, checkpoints).
 * @property {string} sourceUid - A unique identifier for the source of the barcode.
 * @property {TBarcode} barcode - The actual barcode data (e.g., code, time).
 * @property {string} token - A token associated with the saved barcode. This could be used for submission.
 */
export type TSavedBarcode = {
    source: EBarcodeSource;
    sourceUid: string;
    barcode: TBarcode;
    token: string;
};

/**
 * Represents a queue of barcodes.
 *
 * Keys are strings (an uid of the barcode).
 * Values are `TSavedBarcode` objects, containing the saved barcode data.
 */
export type TBarcodeQueue = {
    [key: string]: TSavedBarcode;
}

/**
 * Represents a single barcode item in a list.
 *
 * @property {Date} time - The timestamp when the barcode was scanned.
 * @property {string} code - The actual barcode string.
 * @property {boolean} sent - Indicates whether the barcode data has been sent to the server or processed.
 */
export type TBarcodeListItem = {
    time: Date;
    code: string;
    sent: boolean;
};

/**
 * Represents a barcode with associated metadata.
 */
export class Barcode implements TBarcode {
    uid?: string;
    time: Date;
    code: string;
    message: string;

    control?: string;
    owner?: string;

    // a meaningful control identifier
    name?: string;

    /**
     * Creates a new Barcode instance.
     * @param {string} code The barcode string. Defaults to an empty string.
     * @param {string} message A message associated with the barcode. Defaults to 'new'.
     * @param {Date|string|null} time The timestamp of the barcode. Defaults to the current date and time.
     */
    constructor(code = '', message = 'new', time: Date|string|null = new Date()) {
        this.time = time === null ? null : new Date(time);
        this.code = code;
        this.message = message;
    }

    /**
     * Creates a Barcode instance from a document object.
     *
     * @static
     * @param {TBarcode} doc - The document object containing barcode data.
     * @returns {Barcode} A new Barcode instance.
     */
    static fromDoc(doc: TBarcode): Barcode {
        const {time, code, message, ...rest} = doc;
        const barcode = new Barcode(code, message, time);
        Object.assign(barcode, rest);
        return barcode;
    }
}
