import {LngLat} from "../../models/track-point";

/**
 * Converts a JavaScript value into a Firestore-compatible document format.
 *
 * This function takes a value of various types (null, boolean, Date, LngLat, string, number, array, or object)
 * and converts it into an object that represents how Firestore would store that value.
 * The conversion is done according to the type of the input value:
 * - null values are converted to an object with a `nullValue` property.
 * - boolean values - to an object with a `booleanValue` property.
 * - Date objects - to an object with a `timestampValue` property in ISO format.
 * - LngLat instances - to an object with a `geoPointValue` property containing `longitude` and `latitude`.
 * - strings - to an object with a `stringValue` property.
 * - numbers - to either `doubleValue` or `integerValue` based on their format.
 * - arrays - to an object with an `arrayValue` property containing an array of converted values.
 * - objects are converted to an object with a `mapValue` property containing fields for each key-value pair.
 *
 * @param {any} value - The value to be converted into Firestore document format.
 * @returns {object} - An object representing the Firestore-compatible document format of the input value.
 *
 * @example
 * const doc = jsonToDocument({ name: 'Alice', age: 30, active: true });
 * // Returns:
 * // {
 * //   mapValue: {
 * //     fields: {
 * //       name: { stringValue: 'Alice' },
 * //       age: { integerValue: 30 },
 * //       active: { booleanValue: true }
 * //     }
 * //   }
 * // }
 */
export function jsonToDocument(value) {
    if (value === null) {
        return {nullValue: null};
    } else if (value === 'true' || value === 'false' || typeof value == 'boolean') {
        return {booleanValue: value};
    } else if (value instanceof Date) {
        return {timestampValue: value.toISOString()};
    } else if (value instanceof LngLat) {
        return {geoPointValue: {longitude: value.lng, latitude: value.lat}};
    } else if (typeof value == 'string') {
        return {stringValue: value};
    } else if (!isNaN(value)) {
        if (value.toString().indexOf('.') != -1)
            return {doubleValue: value};
        return {integerValue: value};
    } else if (value && value.constructor === Array) {
        return {arrayValue: {values: value.map(v => jsonToDocument(v))}};
    } else if (typeof value === 'object') {
        const obj = {};
        for (const key in value) {
            obj[key] = jsonToDocument(value[key]);
        }
        return {mapValue: {fields: obj}};
    }
}

