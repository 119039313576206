import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {useContext, useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {TAuthEmail} from "../../models/auth";
import {EmailFlow} from "../../models/email-flow";
import {signUpEmail, updateInfo} from "../../services/auth";
import {State, Storage} from "../app-state";

import style from './style.scss';

/**
 * The CreateAccountDialog properties
 * @property {Signal<EmailFlow>} state - The EmailFlow state signal.
 */
type CreateAccountDialogProps = {
    state: Signal<EmailFlow>;
}

/**
 * Represents the state of the CreateAccountDialog.
 *
 * @property {string} email - The user's email address.
 * @property {string} name - The user's display name.
 * @property {string} password - The user's chosen password.
 * @property {string} error - An error message, if any, during account creation. Empty string if no error.
 */
export type CreateAccountDialogState = {
    email: string;
    name: string;
    password: string;
    error: string;
}

/**
 * A dialog component for creating a new user account.
 *
 * This component allows users to enter their email, name, and password to create a new account.
 * It uses Firebase authentication to create the account and handles potential errors like
 * email already in use or weak passwords. It also updates the application's authentication
 * state upon successful account creation.
 *
 * @param {CreateAccountDialogProps} props - The component's props.
 * @param {Signal<EmailFlow>} props.state - The {@link EmailFlow} state signal.
 * @returns {JSX.Element} The CreateAccountDialog component.
 */
const CreateAccountDialog = ({state}: CreateAccountDialogProps): JSX.Element => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage)

    const [credentials, setState] = useState<CreateAccountDialogState>({
        email: state.value.email,
        name: null,
        password: null,
        error: null
    });
    /**
     * Updates the email address in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object triggered by the input field.
     * @returns {void}
     */
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    /**
     * Updates the username in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the input field.
     * @returns {void}
     */
    const updateName = (event) => setState({...credentials, name: event.target.value});
    /**
     * Updates the user password in the component's state.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the input field.
     * @returns {void}
     */
    const updatePassword = (event) => setState({...credentials, password: event.target.value});

    /**
     * Updates the error state within the component.
     *
     * @param {string} state - The error message to display. This should be a human-readable
     * string describing the error that occurred.
     * @returns {void}
     */
    const updateError = (state: string) => setState({...credentials, error: state});

    /**
     * Creates a new user account using email and password.
     *
     * This function collects email, name, and password from the `credentials` object
     * and attempts to create a new user account using Firebase authentication.
     * If successful, it updates the application's authentication state with the user's information.
     * If an error occurs during signup, it logs the error and updates the error state.
     */
    const submitAccount = () => {
        // check mandatory attributes first
        if (!credentials.email || !credentials.name || !credentials.password) {
            return;
        }
        // reset the error state
        updateError(null);
        signUpEmail(credentials.email, credentials.password)
            .then((info: TAuthEmail) => Promise.all([info, updateInfo(info.idToken, credentials.name)]))
            .then(([info, update]: [TAuthEmail, TAuthEmail]) => auth.value = {
                ...info,
                providerId: 'password',
                displayName: update.displayName
            })
            .catch(error => {
                console.error("error", error);
                // EMAIL_EXISTS or WEAK_PASSWORD : Password should be at least 6 characters
                updateError(error.message)
            })
    }

    return (
        <>
            <h2>{t("createAccount.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("createAccount.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error === 'EMAIL_EXISTS' ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("createAccount.exists")}</div>
            <Input type="text" name="name" required autocomplete="name" floatingLabel={true}
                   label={t("createAccount.name")} value={credentials.name} onChange={updateName} />
            <Input type="password" name="password" minlength="6" required autocomplete="new-password"
                   floatingLabel={true}
                   label={t("createAccount.password")} value={credentials.password} onChange={updatePassword} />
            <div className={`${credentials.error?.startsWith('WEAK_PASSWORD') ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("createAccount.weak")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("createAccount.cancel")}</Button>
                <Button onClick={submitAccount} variant="raised" color="primary">{t("createAccount.save")}</Button>
            </div>
        </>
    )
}

export default CreateAccountDialog;
