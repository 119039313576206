import {Brevet, TBrevetShort} from "../models/brevet";


/**
 * Loads an array of brevets from Firestore documents.
 * Takes an object with short brevet description and convert to the Brevet class.
 *
 * @param brevets - Array of Firestore documents representing brevets.
 * @returns Array of Brevet objects.
 */
const loadBrevets = (brevets: TBrevetShort[]): Brevet[] => brevets.map((doc: Brevet) => Brevet.fromDoc(doc));

export default loadBrevets;
